import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import CloseIcon from "@material-ui/icons/Close"
import slugify from "slugify"

function ItemWithDropDown({ title, subItems }) {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ paddingLeft: 20 }}>
          {subItems.map(item => (
            <ListItem button component={Link} to={item.link}>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

function NavMobile(props) {
  const { closeDrawer } = props
  const query = useStaticQuery(graphql`
    query {
      product: allStrapiProduct(sort: { order: ASC, fields: position }) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
      service: allStrapiService(sort: { order: ASC, fields: position }) {
        edges {
          node {
            heading
            slug
          }
        }
      }
    }
  `)
  console.log(query?.product?.edges)
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <List component="nav">
        <ListItem>
          <Box flexGrow={1} />
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/about">
          <ListItemText primary="About" />
        </ListItem>
        <ItemWithDropDown
          title="Services"
          subItems={query.service.edges.map(item => ({
            title: item.node.heading,
            link: `/service/${item.node.slug}`,
          }))}
          color="primary"
        />
        <ItemWithDropDown
          title="Products"
          subItems={query.product.edges.map(item => ({
            title: item.node.name,
            link: `/product/${item.node.slug}`,
          }))}
          color="primary"
        />
        <ListItem button component={Link} to="/training">
          <ListItemText primary="Training" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="https://pacts.phaidelta.com/"
          target={"_blank"}
        >
          <ListItemText primary="PACTS" />
        </ListItem>
        <ItemWithDropDown
          title="Resources"
          subItems={[
            { title: "Blog", link: "/blog" },
            { title: "Case Studies", link: "/case-studies" },
            { title: "Downloads", link: "/downloads" },
          ]}
          color="primary"
        />
        <ListItem button component={Link} to="/contact">
          <ListItemText primary="Get in touch" />
        </ListItem>
      </List>
    </Box>
  )
}

export default NavMobile
