import React from "react"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Box, Link, Typography, Container } from "@material-ui/core"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import CallIcon from "@material-ui/icons/Call"
import MailIcon from "@material-ui/icons/Mail"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

import logo from "../../images/logo_new_white_h.png"

const Footer = () => {
  const classes = useStyles()

  const dataList = useStaticQuery(graphql`
    query {
      service: allStrapiService(sort: { order: ASC, fields: position }) {
        edges {
          node {
            heading
            slug
          }
        }
      }
      product: allStrapiProduct(sort: { order: ASC, fields: position }) {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `)

  return (
    <footer>
      <Box py={5} className={classes.bgColor}>
        <Container>
          <img src={logo} height="50px" alt="phAIdelta" />
          <Box pt={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Box color="secondary.main">
                  <Typography variant="subtitle1" gutterBottom>
                    <Box
                      color="secondary.dark"
                      letterSpacing={3}
                      fontWeight="fontWeightBold"
                    >
                      SERVICES
                    </Box>
                  </Typography>
                  {dataList.service.edges.map((service, key) => {
                    return (
                      <Typography variant="subtitle1" key={key}>
                        <Link
                          color="inherit"
                          underline="none"
                          component={GatsbyLink}
                          to={`/service/${service.node.slug}`}
                        >
                          {service.node.heading}
                        </Link>
                      </Typography>
                    )
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box color="secondary.main">
                  <Typography variant="subtitle1" gutterBottom>
                    <Box
                      color="secondary.dark"
                      letterSpacing={3}
                      fontWeight="fontWeightBold"
                    >
                      PRODUCTS
                    </Box>
                  </Typography>
                  {dataList.product.edges.map((product, key) => {
                    return (
                      <Typography variant="subtitle1" key={key}>
                        <Link
                          color="inherit"
                          underline="none"
                          component={GatsbyLink}
                          to={`/product/${product.node.slug}`}
                        >
                          {product.node.name}
                        </Link>
                      </Typography>
                    )
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box color="secondary.main">
                  <Typography variant="subtitle1" gutterBottom>
                    <Box
                      color="secondary.dark"
                      letterSpacing={3}
                      fontWeight="fontWeightBold"
                    >
                      COMPANY
                    </Box>
                  </Typography>
                  <Typography variant="subtitle1">
                    <Link
                      color="inherit"
                      underline="none"
                      component={GatsbyLink}
                      to="/about"
                    >
                      About
                    </Link>
                  </Typography>
                  <Typography variant="subtitle1">
                    <Link
                      color="inherit"
                      underline="none"
                      component={GatsbyLink}
                      to="/careers"
                    >
                      Careers
                    </Link>
                  </Typography>
                  <Typography variant="subtitle1">
                    <Link
                      color="inherit"
                      underline="none"
                      component={GatsbyLink}
                      to="/blog"
                    >
                      Blog
                    </Link>
                  </Typography>
                  <Typography variant="subtitle1">
                    <Link
                      color="inherit"
                      underline="none"
                      component={GatsbyLink}
                      to="/case-studies"
                    >
                      Case studies
                    </Link>
                  </Typography>
                  <Typography variant="subtitle1">
                    <Link
                      color="inherit"
                      underline="none"
                      component={GatsbyLink}
                      to="/downloads"
                    >
                      Downloads
                    </Link>
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={12} md={3}>
                <Box color="secondary.dark">
                  <Typography variant="subtitle1">
                    Adwal Palkar Shelters
                    <br />
                    Caranzalem
                    <br />
                    Goa - 403 002 India
                  </Typography>
                  <Box pt={1}>
                    <a
                      href="tel:+918010768815"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      <CallIcon />
                    </a>
                    <a
                      href="https://wa.me/918010768815"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      <WhatsAppIcon style={{ marginLeft: 12 }} />
                    </a>
                    <a
                      href="mailto:bd@phaidelta.com"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      <MailIcon style={{ marginLeft: 12 }} />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/phaidelta"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      <LinkedInIcon style={{ marginLeft: 12 }} />
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box py={2} align="center" className={classes.bgColor}>
        <Container>
          <Typography variant="body2" color="secondary">
            phAIdelta © {new Date().getFullYear()}
          </Typography>
        </Container>
      </Box>
    </footer>
  )
}

const useStyles = makeStyles(theme => ({
  bgColor: {
    // backgroundColor: "#161d2e",
    backgroundColor: theme.palette.primary.dark,
  },
}))

export default Footer
