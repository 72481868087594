import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import { Box, Button } from "@material-ui/core"
import { Random } from "react-animated-text"

import ServicesDropdown from "./services-dropdown"
import ResourcesDropdown from "./resources-dropdown"
import ProductsDropdown from "./products-dropdown"

// const CustomButton = withStyles(theme => ({
//   root: {
//     marginLeft: 10,
//     marginRight: 10,
//     backgroundColor: "transparent",
//     "&:hover": {
//       // borderBottom: "2px solid red",
//       backgroundColor: "transparent",
//       "& .imageMarked": {
//         height: 2,
//         width: 18,
//         backgroundColor: theme.palette.primary.main,
//         position: "absolute",
//         bottom: -2,
//         left: "calc(50% - 9px)",
//         transition: theme.transitions.create("opacity"),
//       },
//     },
//   },
// }))(Button)

function Nav() {
  return (
    <>
      <Button color="secondary" component={GatsbyLink} to="/">
        <Box letterSpacing={2} fontWeight="fontWeightBold" px={1}>
          HOME
        </Box>
      </Button>
      <Button color="secondary" component={GatsbyLink} to="/about">
        <Box letterSpacing={2} fontWeight="fontWeightBold" px={1}>
          ABOUT
        </Box>
      </Button>
      <ServicesDropdown />
      <ProductsDropdown />
      <Button color="secondary" component={GatsbyLink} to="/training">
        <Box letterSpacing={2} fontWeight="fontWeightBold" px={1}>
          TRAINING
        </Box>
      </Button>
      <Button
        color="secondary"
        component={GatsbyLink}
        to="https://pacts.phaidelta.com/"
        target={"_blank"}
      >
        <Box letterSpacing={2} fontWeight="fontWeightBold" px={1}>
          <Random
            text="PACTS"
            effect="jump"
            effectChange={0.5}
            effectDuration={1.0}
          />
        </Box>
      </Button>
      <ResourcesDropdown />

      {/* <CustomButton color="#161d2e" component={GatsbyLink} to="/">
        <Box letterSpacing={2} fontWeight="fontWeightBold">
          ABOUT
        </Box>
        <span class="imageMarked" />
      </CustomButton>
      <CustomButton color="#161d2e" component={GatsbyLink} to="/">
        <Box letterSpacing={2} fontWeight="fontWeightBold">
          SERVICES
        </Box>
        <span class="imageMarked" />
      </CustomButton> */}

      {/* <CustomButton color="default" component={Link} to="/services">
        Services
        <span class="imageMarked" />
      </CustomButton>
      <CustomButton color="default" component={Link} to="/products">
        Products
        <span class="imageMarked" />
      </CustomButton> */}
      <Box px={1}>
        <Button
          variant="outlined"
          color="secondary"
          component={GatsbyLink}
          to="/contact"
        >
          Get in touch
        </Button>
      </Box>
    </>
  )
}

export default Nav
