import React from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  Hidden,
  Container,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"

import Nav from "./nav"
import NavMobile from "./navmobile"

import logo from "../../images/logo_new_white_h.png"

const DrawerNav = props => {
  const { drawer, closeDrawer } = props

  return (
    <Drawer
      anchor="top"
      open={drawer}
      onClose={closeDrawer}
      transitionDuration={300}
    >
      <NavMobile closeDrawer={closeDrawer} />
    </Drawer>
  )
}

const Header = () => {
  const [drawer, setDrawer] = React.useState(false)
  const openDrawer = () => {
    setDrawer(true)
  }
  const closeDrawer = () => {
    setDrawer(false)
  }

  return (
    <>
      <DrawerNav drawer={drawer} closeDrawer={closeDrawer} />
      <AppBar position="absolute" color="transparent" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box
              component={GatsbyLink}
              to="/"
              color="inherit"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                textDecoration: `none`,
              }}
            >
              <img src={logo} height="50px" alt="logo" />
            </Box>

            <Box flexGrow={1} />

            <Hidden mdDown>
              <Nav />
            </Hidden>

            <Hidden lgUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={openDrawer}
              >
                <MenuIcon color="secondary" />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default Header
