import React from "react"
import classNames from "classnames"
import { Link as GatsbyLink } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  MenuItem,
  MenuList,
  ClickAwayListener,
  Paper,
  Grow,
  Divider,
  Icon,
  Popper,
  Button,
  Box,
  Typography,
} from "@material-ui/core"

function DropdownButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = param => {
    setAnchorEl(null)
    if (props && props.onClick) {
      props.onClick(param)
    }
  }
  const handleCloseAway = event => {
    if (anchorEl.contains(event.target)) {
      return
    }
    setAnchorEl(null)
  }
  const classes = useStyles()
  const { hoverColor } = props

  return (
    <>
      <Button
        color="secondary"
        aria-label="Resources"
        aria-owns={anchorEl ? "services-list" : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Box letterSpacing={2} fontWeight="fontWeightBold" px={1}>
          Resources
        </Box>
        <b
          className={classNames({
            [classes.caret]: true,
            [classes.caretActive]: Boolean(anchorEl),
          })}
        />
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={"bottom-start"}
        className={classNames({
          [classes.popperClose]: !anchorEl,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="services-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    className={classNames({
                      [classes.dropdownItem]: true,
                      [classes[hoverColor + "Hover"]]: true,
                    })}
                    component={GatsbyLink}
                    to="/blog"
                  >
                    <Typography variant="button">Blog</Typography>
                  </MenuItem>
                  <MenuItem
                    className={classNames({
                      [classes.dropdownItem]: true,
                      [classes[hoverColor + "Hover"]]: true,
                    })}
                    component={GatsbyLink}
                    to="/case-studies"
                  >
                    <Typography variant="button">Case Studies</Typography>
                  </MenuItem>
                  <MenuItem
                    className={classNames({
                      [classes.dropdownItem]: true,
                      [classes[hoverColor + "Hover"]]: true,
                    })}
                    component={GatsbyLink}
                    to="/downloads"
                  >
                    <Typography variant="button">Downloads</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  popperClose: {
    pointerEvents: "none",
  },
  dropdown: {
    // borderRadius: "3px",
    // border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    // top: "100%",
    // zIndex: "1000",
    // minWidth: "160px",
    // padding: "5px 0",
    // margin: "2px 0 0",
    // fontSize: "14px",
    // textAlign: "left",
    // listStyle: "none",
    // backgroundColor: "#fff",
    // backgroundClip: "padding-box",
  },
  menuList: {
    // padding: "0",
  },
  primaryHover: {
    "&:hover": {
      //   backgroundColor: primaryColor,
      color: "#FFFFFF",
    },
  },
  infoHover: {
    "&:hover": {
      //   backgroundColor: infoColor,
      color: "#FFFFFF",
      //   ...infoBoxShadow,
    },
  },
  caret: {
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "4px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },
  caretActive: {
    transform: "rotate(180deg)",
  },
}))

export default DropdownButton
