/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
// import useBeforeLeave from "../../hooks/useBeforeLeave"

import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"
// import ExitIntent from "./exitintent"

import "./global.css"

const Layout = ({ children, siteTitle, description, ogImage }) => {
  const classes = useStyles()
  // const [open, setOpen] = useState(false)

  // useBeforeLeave(() => {
  //   setOpen(true)
  // })

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  return (
    <div className={classes.body}>
      {/* <ExitIntent
        handleClose={() => {
          setOpen(false)
        }}
        open={open}
      /> */}
      <Header siteTitle={data.site.siteMetadata.title} />
      <SEO
        title={siteTitle}
        description={description}
        ogImage={data.site.siteMetadata.siteUrl + ogImage}
      />
      <main className={classes.main}>{children}</main>
      <Footer />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  body: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    overflow: "hidden",
  },
}))

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
